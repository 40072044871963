<template>
  <b-form-group class="pdf-schema-form-date-time-group">
    <validation-observer ref="observer" v-slot="{ invalid }" />
    <!-- Month ------------------------------------------------------------------------------------>
    <b-row
      v-if="displayField('month')"
      class="sortable-field-container"
    >
      <b-col xs="12" class="mobile-padding">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.month)"
          name="Month"
        >
          <b-form-select
            v-model="formData[fieldIds.month]"
            :state="validationState(validationContext)"
            :options="months"
            :disabled="isDisabledFieldComponent('month')"
            placeholder="Month"
            @input="monthUpdated"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.month)"
          >
            <template #first>
              <option :value="null" disabled>
                Select Month
              </option>
            </template>
          </b-form-select>
          <p v-if="errors('Month')" class="invalid">
            {{ errors('Month') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Day -------------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('day')"
      class="sortable-field-container"
    >
      <b-col xs="12" class="mobile-padding">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.day)"
          name="Day"
        >
          <b-form-select
            v-model="formData[fieldIds.day]"
            :state="validationState(validationContext)"
            :options="days"
            :disabled="isDisabledFieldComponent('day') || disableDayField"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.day)"
          >
            <template #first>
              <option :value="null" disabled>
                Select Day
              </option>
            </template>
          </b-form-select>

          <p v-if="errors('Day')" class="invalid">
            {{ errors('Day') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Year ------------------------------------------------------------------------------------->
    <b-row
      v-if="displayField(year)"
      class="sortable-field-container"
    >
      <b-col xs="12" class="mobile-padding">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(yearField)"
          name="Year"
        >
          <b-form-select
            v-model="formData[yearField]"
            :state="validationState(validationContext)"
            :options="years"
            :disabled="isDisabledFieldComponent(year)"
            class="resource-select"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(yearField)"
            @input="yearUpdated"
          >
            <template #first>
              <option :value="null" disabled>
                Select Year
              </option>
            </template>
          </b-form-select>
          <p v-if="errors('Year')" class="invalid">
            {{ errors('Year') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Hour ------------------------------------------------------------------------------------->
    <b-row
      v-if="displayField('hour')"
      class="sortable-field-container"
      :tabindex="schemaTabIndex(fieldIds.hour)"
    >
      <b-col xs="12" class="mobile-padding">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.hour)"
          name="Hour"
        >
          <b-form-select
            v-model="formData[fieldIds.hour]"
            :state="validationState(validationContext)"
            :options="hours"
            :disabled="isDisabledFieldComponent('hour')"
            class="resource-select"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.hour)"
          >
            <template #first>
              <option :value="null" disabled>
                Select Hour
              </option>
            </template>
          </b-form-select>
          <p v-if="errors('Hour')" class="invalid">
            {{ errors('Hour') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PdfSchemaFormDateTimeGroup',
  components: {},
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldIds: {},
      date: null,
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'mapFieldIdsToGroupedFieldsParts',
      'isDisabledField',
      'validationRulesForFieldId',
      'validationState',
      'resourceFields',
      'schemaTabIndex',
    ]),
    hours() {
      const hours = []
      for (let i = 1; i <= 24; i++) {
        hours.push(`${i}:00`)
      }
      return hours
    },
    days() {
      let days = []
      if (this.formData[this.fieldIds.month]) {
        const monthIndex = this.months.findIndex(month => month === this.formData[this.fieldIds.month])
        if (monthIndex !== -1) {
          const totalDays = new Date(new Date().getFullYear(), monthIndex + 1, 0).getDate()
          days = Array.from({ length: totalDays }, (_, i) => i + 1)
        }
      } else {
        days = Array.from({ length: 31 }, (_, i) => i + 1)
      }
      return days
    },
    years() {
      return this.generateYearOptions(5)
    },
    disableDayField () {
      return (this.displayField('month') && !this.formData[this.fieldIds?.month])
    },
    year() {
      return this.displayField('year_YY') ?
        'year_YY' :
        'year'
    },
    yearField() {
      return this.year === 'year_YY' ?
        this.fieldIds.year_YY :
        this.fieldIds.year
    },
    isDayValid() {
      return this.days.includes(this.formData[this.fieldIds.day])
    },
  },
  beforeMount() {
    this.fieldIds = this.mapFieldIdsToGroupedFieldsParts(this.fields)
    this.setDefaultDateFieldValues()
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
      'setFormData',
    ]),
    displayField(fieldName) {
      return Object.keys(this.fieldIds).includes(fieldName)
    },
    isDisabledFieldComponent(fieldName) {
      return this.isDisabledField(this.fields.find(f => f.id === this.fieldIds[fieldName]))
    },
    errors(fieldName) {
      return this.$refs.observer?.$_veeObserver?.errors && this.$refs.observer.$_veeObserver.errors[fieldName] ?
        this.$refs.observer.$_veeObserver.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
    monthUpdated(_value) {
      // Validate the Day value when the month is changed
      if (this.fieldIds?.day && this.formData[this.fieldIds.day] && !this.isDayValid) {
        this.formData[this.fieldIds.day] = null
      }
    },
    yearUpdated(value) {
      this.formData[this.yearField] = this.year === 'year_YY' ? value.padStart(2, '0') : value
    },
    setDefaultDateFieldValues() {
      const newFormData = cloneDeep(this.formData)
      if (this.displayField('month')) newFormData[this.fieldIds.month] = this.formData[this.fieldIds.month] || null
      if (this.displayField('day')) newFormData[this.fieldIds.day] = this.formData[this.fieldIds.day] || null
      if (this.displayField('hour')) newFormData[this.fieldIds.hour] = this.formData[this.fieldIds.hour] || null
      if (this.displayField(this.year)) newFormData[this.yearField] = this.setDefaultYearValue()
      this.setFormData(newFormData)
    },
    twoDigitYear(fullYear) {
      const lastTwoDigits = fullYear % 100
      return lastTwoDigits.toString().padStart(2, '0')
    },
    setDefaultYearValue() {
      if (this.formData[this.yearField]) return this.formData[this.yearField]

      const currentYear = new Date().getFullYear()
      return this.year === 'year_YY' ? this.twoDigitYear(currentYear) : currentYear
    },
    generateYearOptions(numYearsBeforeAndAfter) {
      const currentYear = new Date().getFullYear()
      const years = []

      for (let i = currentYear - numYearsBeforeAndAfter; i <= currentYear + numYearsBeforeAndAfter; i++) {
        years.push({
          value: this.year === 'year_YY'  ? (i % 100).toString().padStart(2, '0') : i.toString(),
          text: i.toString(),
        })
      }

      return years
    },
  },
}
</script>
